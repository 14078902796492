import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Container, Navbar, Button, Nav, NavDropdown } from "react-bootstrap"

import { AuthContext } from "../context/auth"
import firebase from "gatsby-plugin-firebase"

import Facebook from "../images/icons/facebook.svg"
import Twitter from "../images/icons/twitter.svg"
import Instagram from "../images/icons/instagram.svg"
import LinkedIn from "../images/icons/linkedin.svg"

const Header = ({ siteTitle }) => {
  const { user } = useContext(AuthContext)
  const data = useStaticQuery(graphql`
    query {
      pocamIcon: file(relativePath: { eq: "pocam-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const handleLogout = async () => {
    await firebase.auth().signOut()
    console.log("logged out")
  }

  return (
    <Navbar fixed="top" bg="light" expand="md">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <Img
            className="navbar-icon"
            alt={siteTitle}
            fluid={data.pocamIcon.childImageSharp.fluid}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/who-is-pocam">
              Who is POCAM?
            </Nav.Link>
            <Nav.Link as={Link} to="/call-for-equity">
              Call for Equity
            </Nav.Link>
            <Nav.Link as={Link} to="/visible-and-vocal">
              Visible & Vocal
            </Nav.Link>

            <NavDropdown title="Events" id="basic-nav-dropdown">
              <NavDropdown.Item href="https://lu.ma/gvv3eez1" target="_blank">Visible & Vocal 4</NavDropdown.Item>
              <NavDropdown.Item href="/events/beyond-tropes-tokenism-and-talk-recap/">APG x POCAM Hackathon</NavDropdown.Item>
              <NavDropdown.Item href="#sign-up">Subscribe for updates</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link as={Link} to="/join-pocam">
              Join POCAM
            </Nav.Link>
            {/* <Nav.Link href="#link">Canadian Creatives</Nav.Link> */}
          </Nav>
          <Nav>
            <ul className="list-inline mb-0">
              <li className="list-inline-item mb-2">
                <Button as={Link} to={"/update-status"} size="sm">
                  Call for Equity Progress Tracker
                </Button>
              </li>
              {user ? (
                <li className="list-inline-item mb-2">
                  <Button onClick={handleLogout} variant="secondary" size="sm">
                    Log out
                  </Button>
                </li>
              ) : null}
              <br className="d-block d-sm-none mt-2" />
              <li className="list-inline-item mr-0">
                <Nav.Link
                  className="nav-social px-2"
                  target="_blank"
                  href="https://www.facebook.com/wearepocam"
                >
                  <Facebook />
                </Nav.Link>
              </li>
              <li className="list-inline-item mr-0">
                <Nav.Link
                  className="nav-social px-2"
                  target="_blank"
                  href="https://twitter.com/wearepocam/"
                >
                  <Twitter />
                </Nav.Link>
              </li>
              <li className="list-inline-item mr-0">
                <Nav.Link
                  className="nav-social px-2"
                  target="_blank"
                  href="https://instagram.com/wearepocam/"
                >
                  <Instagram />
                </Nav.Link>
              </li>
              <li className="list-inline-item mr-4">
                <Nav.Link
                  className="nav-social px-2"
                  target="_blank"
                  href="https://linkedin.com/company/we-are-pocam/"
                >
                  <LinkedIn />
                </Nav.Link>
              </li>
              <br className="d-block d-sm-none mt-2" />
              <li className="list-inline-item mb-0">
                <Button href="https://www.zeffy.com/en-CA/embed/donation-form/89425c6d-cdc6-4337-9ab9-1ea63c52e64e" target="blank" size="lg">
                  Donate
                </Button>
              </li>
            </ul>
          </Nav>
          {/* <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-success">Search</Button>
          </Form> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
