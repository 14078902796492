/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import Header from "./header"
// import "./layout.css"
import "../styles/index.scss"

import Facebook from "../images/icons/facebook.svg"
import Twitter from "../images/icons/twitter.svg"
import Instagram from "../images/icons/instagram.svg"
import LinkedIn from "../images/icons/linkedin.svg"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Helmet>
        <script
          src="https://kit.fontawesome.com/d9f00fbace.js"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div>
        <main>{children}</main>
        <footer className="py-4 footer">
          <Container className="text-center">
            <Row>
              <Col>
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <a
                      className="footer-social"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/wearepocam/"
                    >
                      <Facebook />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      className="footer-social"
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/wearepocam/"
                    >
                      <Twitter />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      className="footer-social"
                      target="_blank"
                      rel="noreferrer"
                      href="https://instagram.com/wearepocam/"
                    >
                      <Instagram />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      className="footer-social"
                      target="_blank"
                      rel="noreferrer"
                      href="https://linkedin.com/company/we-are-pocam/"
                    >
                      <LinkedIn />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col>
                contact us at{" "}
                <a href="mailto:steerco@wearepocam.ca">steerco@wearepocam.ca</a>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
